<template>

    <div id="kt_content_container" class="container">
        <!--begin::Card-->
        <div class="card card-custom">

            <div class="card-body">
                <MapChart></MapChart>
            </div>
            <!--end::Form-->
        </div>

    </div>

</template>

<script>
    import {l}           from "@/core/helpers/lang";
    import { onMounted } from 'vue';
    import MapChart from '/src/components/backoffice/chart/MapChart.vue';

    export default ({
        name: 'Map',
        components: {
          MapChart
        },
        data() {
            return {
                vueTel: {
                    phone: "+90",
                    props: {
                        preferredCountries: ["TR"],

                        mode: "international",
                        inputOptions: {
                            showDialCode: true
                        },
                        disabledFormatting: true,
                        wrapperClasses: "country-phone-input"
                    }
                },
            };
        },
        setup(){
          return{
            l
          }
        },
    });
</script>


<style scoped>
    .styleInput {
        position: relative;
    }

    .styleInput label::before {
        content: attr(title);
        position: absolute;
        top: 0;
        left: 15px;
        line-height: 40px;
        font-size: 13px !important;
        color: #777;
        transition: 300ms all;
    }

    .styleInput input {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        color: #222;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    .styleInput input:focus {
        border-color: #777;
        outline: none;
    }

    .styleInput input:valid+label::before {
        content: attr(data-title);
        line-height: 20px;
        font-size: 12px;
        top: -10px;
        background-color: #fff;
        padding: 0 6px;
        left: 6px;
    }

    .styleInput input:focus+label::before {
        content: attr(data-title) !important;
        line-height: 20px;
        font-size: 12px;
        top: -20px;
        background-color: #fff;
        padding: 8px;
        left: 6px;
        color: #777;
    }


    .col-lg-6 #field .input-group input:focus+label::before {
        left: 60px !important;
        z-index: 99;
        top: 25px;

    }

    .col-lg-6 #field .input-group #tag::before {
        content: attr(title);
        position: absolute;
        top: 25px;
        left: 30px !important;
        line-height: 40px;
        font-size: 11px !important;
        color: #777;
        transition: 300ms all;
    }


    @media only screend and (max-width: 900px) {
        .styleInput {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;


        }

        .styleInput input {
            display: inline-block;
        }
    }

    .country-phone-input .dropdown:focus {
        outline: none;
        color: grey;
    }
</style>